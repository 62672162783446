import React, { FunctionComponent } from 'react';
import { useGlobalTranslations } from '../../hooks/use-global-translations';
import { Button } from '../button/button';
import { Container } from '../container/container.component';
import { InternalLink } from '../internal-link/internal-link';
import styles from './job-offert-hero.module.scss';
import { IElevatoOffer } from '../../templates/job-offert';

interface IJobOffertHero {
  careerPageUrl: string;
  careerPageTitle: string;
  jobOffertData: IElevatoOffer;
}

export const JobOffertHero: FunctionComponent<IJobOffertHero> = ({
  careerPageTitle,
  careerPageUrl,
  jobOffertData: { title, jobLocation, formUrl }
}) => {
  const { jobOffertApplication } = useGlobalTranslations();

  return (
    <Container>
      <div className={styles.heroWrapper}>
        <InternalLink
          className={styles.linkBack}
          url={careerPageUrl ? careerPageUrl : '#'}
        >
          {careerPageTitle}
        </InternalLink>
        <div className={styles.heroTitle}>
          <div>
            <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          </div>
          <p className={styles.jobLocation}>{jobLocation}</p>
        </div>
        <a
          href={formUrl}
          className={styles.heroButton}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            className={styles.button}
            border="rectangular"
            size="large"
            CTA={true}
            text={jobOffertApplication}
            theme="transparent"
            transofrmUppercase={true}
          />
        </a>
      </div>
    </Container>
  );
};
