import { graphql } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  useContext,
  useEffect,
  FunctionComponent,
  useMemo
} from 'react';
import get from 'lodash/get';
import { Navbar } from '../components/navbar/navbar';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import { PageProps } from 'gatsby';
import { SeoComponent } from '../components/seo-component/seo-component';
import { Footer } from '../components/footer/footer';
import { pageDataResolver } from '../utils/pageDataResolver';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import '../styles/global.scss';
import '../styles/reset.css';
import { Container } from '../components/container/container.component';
import { ActiveJobOfferts } from '../components/active-job-offerts/active-job-offerts';
import styles from '../styles/job-offert.module.scss';
import { JobOffertHero } from '../components/job-offert-hero/job-offert-hero';
import { useGlobalTranslations } from '../hooks/use-global-translations';
import { PageSectionModel } from '../models/page-section-model';
import { PageSection } from '../components/page-section/page-section';
import { Button } from '../components/button/button';
import { withCookieBar } from '../hoc/withCookieBar';
import { IElevatoListItem } from './page';
import { Models } from '../models';
import sanitizeHtml from 'sanitize-html';

export interface IElevatoOffer extends IElevatoListItem {
  shortDescription: string;
  offerDetails: string;
  offerDetailsLabel: string;
  duties: string;
  dutiesLabel: string;
  requiredSkills: string;
  jobType: string;
  requiredSkillsLabel: string;
  formUrl: string;
}

interface IData {
  kontentItemActiveJobOfferts: IActiveJobOfferts;
  allElevatoApiItems: {
    nodes: IElevatoOffer[];
  };
  kontentItemPage: IPage;
  careerPage: {
    elements: {
      basic_page_settings__title: {
        value: string;
      };
      basic_page_settings__url: {
        value: string;
      };
    };
  };
  benefitsSection: IPageSection;
  cookieSection: ICookieData;
}

type JobOffertPageProps = PageProps<IData, IPageContext>;

const JobOffert: React.FunctionComponent<JobOffertPageProps> = props => {
  const { dispatch, state } = useContext(AppStore);
  const {
    jobTypeTitle,
    jobOfferDesctiptionTitle,
    joinOurAwesomeTeam
  } = useGlobalTranslations();

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });
    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          currentUrl: props.pageContext.pageUrl || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();
    const debouncedResize = debounce(handleResize, 300);

    debouncedResize();
    handleChangePageState();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    props.data.cookieSection.elements.button_cta.value,
    props.data.cookieSection.elements.text.value,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    state.showActiveDevice,
    state.showPageState.currentUri
  ]);

  const jobOffertData = useMemo(
    () =>
      props.data.allElevatoApiItems
        ? props.data.allElevatoApiItems.nodes.find(
            item => item.elevatoId === props.pageContext.elevatoId
          )
        : null,
    [props.data.allElevatoApiItems, props.pageContext.elevatoId]
  );

  const filteredActiveJobOfferts = useMemo(
    () =>
      props.data.allElevatoApiItems
        ? props.data.allElevatoApiItems.nodes.filter(
            item => item.elevatoId !== props.pageContext.elevatoId
          )
        : null,
    [props.data.allElevatoApiItems, props.pageContext.elevatoId]
  );

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  const careerPageTitle = useMemo(() => {
    const title = get(
      props.data.careerPage,
      'elements.basic_page_settings__title.value'
    );
    return title.split('-')[0] || title;
  }, [props.data.careerPage]);

  const careerPageUrl = useMemo(() => {
    return (
      get(props.data.careerPage, 'elements.basic_page_settings__url.value') ||
      null
    );
  }, [props.data.careerPage]);

  const currentOpeningsHeaderText = useMemo(() => {
    return get(
      props.data.kontentItemActiveJobOfferts,
      'elements.header_value.value',
      ''
    );
  }, [props.data.kontentItemActiveJobOfferts]);

  const readMoreButtonText = useMemo(() => {
    return get(
      props.data.kontentItemActiveJobOfferts,
      'elements.apply_button_text.value',
      ''
    );
  }, [props.data.kontentItemActiveJobOfferts]);

  const applyButtonText = useMemo(() => {
    return get(
      props.data.kontentItemActiveJobOfferts,
      'elements.job_offer_apply_button_text.value',
      ''
    );
  }, [props.data.kontentItemActiveJobOfferts]);

  const benefitsData = useMemo(() => {
    return PageSectionModel.create(props.data.benefitsSection);
  }, [props.data.benefitsSection]);

  return (
    <>
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      {jobOffertData && (
        <>
          <div className={styles.hero}>
            <JobOffertHero
              careerPageUrl={careerPageUrl}
              careerPageTitle={careerPageTitle}
              jobOffertData={jobOffertData}
            />
            <div className={styles.jobOffert}></div>
          </div>
          <div className={styles.jobOffertContent}>
            <Container>
              <div className={styles.contentWrapper}>
                {jobOffertData.jobType && (
                  <div className={styles.fieldWrapper}>
                    <strong>{jobTypeTitle}:</strong> {jobOffertData.jobType}
                  </div>
                )}

                {jobOffertData.shortDescription && (
                  <div className={styles.fieldWrapper}>
                    <h2 className={styles.fieldTitle}>
                      {jobOfferDesctiptionTitle}
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(jobOffertData.shortDescription)
                      }}
                    ></div>
                  </div>
                )}

                {jobOffertData.dutiesLabel && jobOffertData.duties && (
                  <div className={styles.listWrapper}>
                    <h2 className={styles.listTitle}>
                      {jobOffertData.dutiesLabel}
                    </h2>
                    <div
                      className={styles.list}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(jobOffertData.duties)
                      }}
                    ></div>
                  </div>
                )}
                {jobOffertData.requiredSkillsLabel &&
                  jobOffertData.requiredSkills && (
                    <div className={styles.listWrapper}>
                      <h2 className={styles.listTitle}>
                        {jobOffertData.requiredSkillsLabel}
                      </h2>
                      <div
                        className={styles.list}
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(jobOffertData.requiredSkills)
                        }}
                      ></div>
                    </div>
                  )}
                {jobOffertData.offerDetailsLabel && jobOffertData.offerDetails && (
                  <div className={styles.listWrapper}>
                    <h2 className={styles.listTitle}>
                      {jobOffertData.offerDetailsLabel}
                    </h2>
                    <div
                      className={styles.list}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(jobOffertData.offerDetails)
                      }}
                    ></div>
                  </div>
                )}
              </div>
            </Container>
          </div>
          <PageSection {...benefitsData} />
          <div className={styles.ctaSection}>
            <div className={styles.ctaSectionContent}>
              <h3 className={styles.ctaSectionHeader}>{joinOurAwesomeTeam}</h3>
              <a
                className={styles.ctaSectionLink}
                href={jobOffertData.formUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  border="rectangular"
                  size="large"
                  CTA={true}
                  theme="transparent"
                  text={applyButtonText.toUpperCase()}
                />
              </a>
            </div>
          </div>
        </>
      )}
      {filteredActiveJobOfferts && (
        <div className={styles.activeJobOffertsContainer}>
          <ActiveJobOfferts
            applyButtonText={readMoreButtonText}
            jobOffersElevato={filteredActiveJobOfferts}
            headerValue={currentOpeningsHeaderText}
          />
        </div>
      )}
      {pageData.footerData.linkColumns.length && (
        <Footer {...pageData.footerData} />
      )}
    </>
  );
};

export const Head = (props: JobOffertPageProps) => {
  const jobOffertData = useMemo(
    () =>
      props.data.allElevatoApiItems
        ? props.data.allElevatoApiItems.nodes.find(
            item => item.elevatoId === props.pageContext.elevatoId
          )
        : null,
    [props.data.allElevatoApiItems, props.pageContext.elevatoId]
  );

  const seoData = useMemo(() => {
    const title = jobOffertData ? jobOffertData.title : '';
    return jobOffertData
      ? Models.JobOffertSeoModel.create(
          props.data.kontentItemActiveJobOfferts,
          title
        )
      : null;
  }, [jobOffertData, props.data.kontentItemActiveJobOfferts]);

  return <SeoComponent {...seoData} />;
};

export default withAppStore(withCookieBar(JobOffert as FunctionComponent<{}>));

export const query = graphql`
  query($pageLanguage: String) {
    kontentItemPage(
      system: {
        language: { eq: $pageLanguage }
        codename: { eq: "crm_software_development" }
      }
    ) {
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    careerPage: kontentItemPage(
      system: { codename: { eq: "career" }, language: { eq: $pageLanguage } }
    ) {
      elements {
        basic_page_settings__title {
          value
        }
        basic_page_settings__url {
          value
        }
      }
    }
    benefitsSection: kontentItemPageSection(
      system: {
        codename: { eq: "benefits_job_offert" }
        language: { eq: $pageLanguage }
      }
    ) {
      ...FragmentPageSection
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
    kontentItemActiveJobOfferts(system: { language: { eq: $pageLanguage } }) {
      ...FragmentActiveJobOffers
    }
    allElevatoApiItems: allElevatoApiItemsEn {
      nodes {
        ...FragmentElevatoApiItems
      }
    }
  }
`;
